@font-face {
    font-family: 'Maison Mono Trial';
    font-style: normal;
    font-weight: 400;
    src:
            url('../static/fonts/MaisonMonoTRIAL-Regular.ttf') format('truetype'),
            url('../static/fonts/MaisonMonoTRIAL-Regular.woff') format('woff'),
            url('../static/fonts/MaisonMonoTRIAL-Regular.woff2') format('woff2')
}
@font-face {
    font-family: 'Mint Grotesk Trial';
    font-style: normal;
    font-weight: 400;
    src:
            url('../static/fonts/MintGroteskRegularTrial.ttf') format('truetype'),
            url('../static/fonts/MintGroteskRegularTrial.woff') format('woff'),
            url('../static/fonts/MintGroteskRegularTrial.woff2') format('woff2')
}


*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body
{
    overflow-x: hidden;
    font-family: 'Maison Mono Trial', sans-serif;
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.944vw;
    background: #101010;
}
a {
    text-decoration: none;
}
img {
    width: 100%;
    height: auto;
}
.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    outline: none;
    z-index: -1;
}
.container {
    width: 90%;
    margin: 0 auto;
    overflow-x: hidden;

}
.nira_wrap {
    width: 100%;
    min-height: 100vh;
    background: #111111;
    position: relative;
}
.nira_one_model {
    height: 50vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.nira_two_models {
    height: 50vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
}
.w50 {
    width: 50%;
    position: relative;
}
.nira_one_model_text {
    position: absolute;
    bottom: 5%;
    left: 5%;
    color: #FFFFFF;
    max-width: 27.78vw;
}
.nira_two_models .nira_one_model_text {
    position: absolute;
    bottom: -1%;
    left: 1%;
    color: #FFFFFF;
    max-width: 12.78vw;
    transform: scale(0.7);
}
.nira_two_models .nira_one_model_text {
    position: absolute;
    bottom: -1%;
    right: 1%;
    max-width: 18.78vw;
    transform: scale(0.7);
}


#niraEmbedAsset1,
#niraEmbedAsset2,
#niraEmbedAsset3,
#niraEmbedAsset4,
#niraEmbedAsset5,
#niraEmbedAsset6 {
    width: 100%;
    height: 100%;
}
.text_block span{
    text-decoration: underline;
}
.text_block {
    font-size: 2.08vw;
    margin-top: 160px;
    margin-left: 5%;
}
.line_port {
    height: 10px;
    width: 45%;
    background: linear-gradient(135deg, #FBD001 0%, #643BE0 100%);
    margin-top: -10px;
}
.image_port_cube {
    position: absolute;
    max-width: 20vw;
    right: 0;
    top: 150px;
    z-index: -1;
}

header {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 6.7vw;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6.7vw;
}
.left_col {
    width: 50%;
}
.menu {
    width: 50%;
    justify-content: flex-end;
    color: #FFFFFF;
}
.menu ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    li {
        list-style: none;
        overflow: hidden;
        position: relative;

        a {
            display: block;
        }

    }
}
.menu .line_m:after{
    content: "";
    border-bottom: 1px solid #FFFFFF;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 17px;
    transform-origin: bottom center;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
}
.portfolio .menu .line_m:after{
    border-bottom: 1px solid #111;
}
.menu .line_m:hover:after{
    content: "";
    transform: scaleX(1);
    transition: transform 0.25s ease-out;
}
.download_btn a{
    background: #643BE0;
    padding: 10px 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
}
.download_btn a:hover{
    background: #643BE090;
    transition: all 0.3s;
}
.menu a{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}
.portfolio .menu li a{
    color: #111111;
}
.portfolio .container {
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
body.portfolio {
    background: #FFFFFF;
}
.portfolio .menu li:last-child a{
    color: #FFF;
}
section {
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.header_section {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
}
.header_bot {
    color: #FFFFFF;
    width: 100%;
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.address1,.address2,.soundbar{
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    line-height: 16px;
}
.slogan {
    font-family: 'Mint Grotesk Trial',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3.125vw;
    line-height: 3.125vw;
    white-space: nowrap;
}
.slogan span {
    position: relative;
}
.slogan span:after {
    content: "";
    position: absolute;
    height: 0.2778vh;
    width: 0;
    background: #FFFFFF;
    top: 2.812vw;
    left: 0;
}
.charsParent {
    overflow: hidden;
    height: 51%;
}
.wordsParent {
    overflow: hidden;
}
.content {
    background: #101010;
    overflow: hidden;
}
.content .white_block {
    overflow: hidden;
}
.y_line {
    width: 46%;
    height: 10px;
    background: linear-gradient(135deg, #FBD001 0%, #643BE0 100%);
}
.white_block {
    background: #FFFFFF;
}
.top_cube {
    width: 28.4vw;
    margin: 0 auto;
    will-change: transform;
}
.wordsChild, .charsChild {
    will-change: transform;
}
.accordion {
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1vw;
    font-weight: bold;
    transition: 0.4s;
    border-top: 1px solid #ccc;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s;
}

.accordion.active, .accordion:hover {
    background-color: #000;
    color: #FFFFFF;
    transition: all 0.5s;
}

.accordion:hover .svg_arrow path {
    fill: white;
    transition: all 0.5s;
}
.accordion.active .svg_arrow {
    transform: rotate(90deg);
    transition: all 0.5s;
}
.accordion.active .svg_arrow path{
    fill: white;
}
.panel:last-child{
    border-bottom: 1px solid #ccc;
}
.panel {
    padding: 0px 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    transition: all 0.5s;
}
.sub_title {
    padding: 0px 15px;
    width: max-content;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Mint Grotesk Trial';
    font-size: 0.83vw;
    position: relative;
    background: #643BE0;
}
.sub_title .sub_bg {
    width: 103%;
    height: 105%;
    position: absolute;
    left: -1px;
    top: -1px;
    background: #FFFFFF;
    transform-origin: right;
    transform: scaleX(1);
    will-change: transform;
}
.slider .sub_title .sub_bg,
.client_section .sub_title .sub_bg{
    background: #111111;
}

.content .sub_title{
    margin: 100px auto;
}
h1 {
    max-width: 800px;
    text-align: center;
    margin: 45px auto 144px;
    font-size: 2.08vw;
    line-height: 2.7778vw;
    display: block;
}
h2 {
    font-size: 2.08vw;
    line-height: 2.7778vw;
    margin: 50px 0;
}
.col-50 {
    width: 50%;
    padding: 0 5vw;
    position: relative;
}
.faq .col-50 {
    padding: 0 8vw 0 0;
}


.about_section {
    display: flex;
    margin-bottom: 200px;
}
.top_line {
    height: 10px;
    width: 100%;
    background: #111111;
    opacity: 0.1;
    margin-bottom: 30px;
}
.bg_t {
    background: transparent;
}
.about_section p{
    margin-bottom: 50px;
}
.slider {
    height: 100vh;
    position: relative;
}
.header_num {
    font-family: 'Mint Grotesk Trial', serif;
    color: #FFFFFF;
    font-size: 1.736vw;
    position: absolute;
    top: 50px;
    left: 50px;
    overflow: hidden;
    width: 4.5vw;
}
.header_num span{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
.header_num .num1{
    right: 0.4vw;
}
.num2, .num3 {
    transform: translate(0,100%);
    //opacity: 0;
}
.status_bar {
    width: 1px;
    height: 200px;
    top: 50%;
    right: 100px;
    transform: translate(0, -50%);
    background: #777777;
    position: absolute;
}
.status_prog {
    width: 1px;
    background: #fff;
    height: 0%;
}
.bottom_text {
    position: absolute;
    bottom: -12%;
    left: 0;
    color: #FFFFFF;
    max-width: 27.78vw;
}
.bottom_text .sub_title {
    margin: 0;
}
.title_in_bl{
    margin: 48px 0;
    font-size: 1.736vw;
}
.see_more {
    border: 1px solid #fff;
    margin: 50px 0;
    width: max-content;
}
.see_more a{
    padding: 10px 30px;
    color: #FFFFFF;
    display: block;
}
.link_w {
    overflow: hidden;
    position: relative;
    display: block;
    pointer-events: none;
}
.seemore_btn_s_move {
    position: absolute;
    top: 0;
    transform: translate(0,100%);
}
.mission {
    display: flex;
    padding: 230px 0;
}
.mission h2{
    margin: 67px auto;
}
.bespoke_service {
    display: flex;
    padding: 230px 0;
    position: relative;
}

.bespoke_service .col-50{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bespoke_service .cube_img{
    position: absolute;
    top: 0;
    right: -15vw;
    max-width: 46.8vw;
}
.note .cube_img{
    max-width: 27.7vw;
}
.mission_section .cube_img {
    max-width: 28vw;
}
.mission_section p {
    margin: 60px 0;
}
.bespoke_service p {
    margin: 60px 0;
}
.white_block p {
    margin: 60px 0;
}
.mission_section .cover{
    position: absolute;
    max-width: 28vw;
    z-index: 2;
    left: 11%;
    top: -25%;
}
.bespoke_service .cover_for_full_left{
    position: absolute;
    max-width: 13.4vw;
    z-index: 2;
    left: -40%;
    top: 50%;
    display: block;
}
.note .cube_img {
    position: relative;
}
.cover_for_full_right{
    max-width: 13.4vw;
    position: absolute;
    left: 90%;
    top: 43%;
    z-index: 2;
    display: block;
}
.note {
    display: flex;
    padding: 230px 0;
}
.price_section {
    height: 100vh;
    min-height: auto;
    color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: end;
    background: #FFFFFF;
}
.price_section .item {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: end;
    position: relative;
    transition: 0.5s ease-in-out;
    overflow: hidden;
}

.price_wrap {
    width: 100%;
    height: 80%;
    display: flex;
    position: relative;

}
.item1{
    background: linear-gradient(135deg, #130930 0%, #643BE0 100%);
    width: 40%;
}
.item2{
    background: linear-gradient(135.38deg, #cba147 -19.82%, #8258b6 100%);
}
.item3{
    background: linear-gradient(135.38deg, #bc925f -19.82%, #744bc8 100%);
}
.item4{
    background: linear-gradient(135.38deg, #af8570 -19.82%, #673edb 100%);
}
.price_section .item.active {
    width: 40%;
}
.name_plan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.08vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
}
.radials {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}
.item1 .radials {
    opacity: 1;
}
.rad_sm {
    background: linear-gradient(180deg, #FD8821 0%, rgba(253, 136, 33, 0.0001) 100%);
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 1.1111vw;
    height: 1.1111vw;
    border-radius: 50%;
    position: absolute;
    left: 75%;
    top: 10%;
}
.rad_med {
    background: linear-gradient(180deg, rgba(100, 59, 224, 0.0001) 0%, #643BE0 100%);
    transform: rotate(-180deg);
    width: 3.8889vw;
    height: 3.8889vw;
    border-radius: 50%;
    position: absolute;
    left: 10%;
    top: 53%;
}
.rad_big {
    background: linear-gradient(180deg, rgba(251, 208, 1, 0.0001) 0%, #FBD001 100%);
    mix-blend-mode: normal;
    opacity: 0.4;
    width: 12.56vw;
    height: 12.56vw;
    border-radius: 50%;
    top: 50%;
    left: 70%;
    position: absolute;
}
.price_section .description {
    padding: 50px;
}
.price_section .price {
    font-size: 1.736vw;
    margin: 20px 0;
}
.item .description{
    opacity: 0;
}
#logo_canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}
.item.active .description{
    opacity: 1;
}
.name_plan span{
    display: flex;
    width: 1.1111vw;
    height: 1.1111vw;
}
.faq_section .white_block .faq{
    display: flex;
    flex-direction: row;
    padding: 150px 0;
}
.faq .text{
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
}
.faq_section h3{
    border-top: 1px solid #ECECEC;
    padding-top: 20px;
    margin: 20px 0;
}
.faq .cube_img{
    position: absolute;
    left: -22%;
    width: 27.7vw;
    overflow: hidden;
}
.testimonials_section .human_img img{
    width: 95%;
}
.testimonials_section .f_plane, .testimonials_section .s_plane {
    width: 105%;
    height: 105%;
    position: absolute;
    top: -2%;
    left: -2%;
    background: #FFFFFF;
    z-index: 2;
}
.testimonials_section .s_plane{
    background: #643BE0;
    z-index: 1;
}
.testimonials_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.testimonials_section .text {
    max-width: 41.66vw;
    margin: 0 auto;
    text-align: center;
}
.testimonials_section .item_wrap .text {
    text-align: left;
    max-width: 80%;
    margin: initial;
}
.testimonials_section .text .sub_title {
    margin: 150px auto 20px;
}
.testimonials.row {
    display: flex;
}
.testimonials.row:last-child {
    padding: 0 0 200px 0;
}
.testimonials_section .text p{
    font-size: 2.083vw;
    font-family: 'Mint Grotesk Trial', serif;
}
.human_img_wrap .line{
    width: 105%;
    height: 10px;
    background: linear-gradient(135deg, #FBD001 0%, #643BE0 100%);
    margin: -15px 0 0 0;
}
.human_img_wrap.woman .line{
    width: 105%;
}
.human_img{
    position: relative;
    overflow: hidden;
}
.human_img.man {
    max-width: 22.9vw;
}
.human_img.woman {
    margin-top: 475px;
    max-width: 32.63vw;
}
.mt150 {
    margin-top: 150px;
}
.testimonials .name:not(p){
    margin-top: 50px;
    font-size: 1.736vw;
}
.testimonials .name p{
    font-size: 1vw;
}
.testimonials .name p{
    margin: 5px auto 25px;
}
.slider_text2, .slider_text3 {
    position: absolute;
    top: 0px;
    opacity: 0;
}
.absolute_img {
    position: absolute;
    right: -10vw;
    max-width: 27.78vw;
    top: 30px;
}
.testimonials_section .col-50 {
    padding: 0 8vw;
}
.col-30 {
    width: 50%;
    padding: 0 1.389vw;
}
.col-70 {
    width: 50%;
    padding: 0 1.389vw;
}
.client_section {
    width: 100%;
    color: #FFFFFF;
    height: 100vh;
    background: url("../static/images/bg_client.png");
}
.client_section_wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    height: 100%;
}
.client_section .text p{
    font-size: 2.08vw;
    line-height: 3.125vw;
    max-width: 28.05vw;
}
.grid img{
    width: 10vw;
    height: auto;
    display: flex;
    padding: 1.041vw;
    display: block;
    object-fit: scale-down;
}
.grid {
    display: flex;
    flex-wrap: wrap;
}
.client_section .sub_title{
    margin-bottom: 20px;
}
.client_section .text span{
    text-decoration: underline;
}
footer {
    background: #FFFFFF;
    padding: 7vw 7vw 0 7vw;


}
.social_block {
    display: flex;
    border-bottom: 1px solid #11111120;
    justify-content: space-between;
    position: relative;
    padding-bottom: 120px;
}
.social_block .text{
    max-width: 43vw;
    margin-top: 30px;
}
.left_side {
    display: flex;
}
.right_side {
    display: flex;
    position: relative;
}
.logo_name {
    font-size: 1.736vw;
}
.social_block .left_side{
    display: flex;
    flex-direction: column;
}
.btn_social {
    border: 2px solid #111;
    padding: 10px 20px;
    height: max-content;
    cursor: pointer;
}

.mobile_menu {
    display: none;
}
.modal {
    position: absolute;
    left: 50%;
    width: 165px;
    height: 165px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.download_modal {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    opacity: 0;
    visibility: hidden;
}
.download_modal .overlay{
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100vw;
    height: 100vh;
    background: #000000;
    opacity: 0;
    z-index: -1;
}
.download_modal .wrapper {
    opacity: 1;
    width: 40vw;
    height: 46vh;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.inp {
    position: relative;
    margin: 20px 0 35px;
    width: 100%;
    max-width: 26vw;
    border-radius: 3px;
    overflow: hidden;
}
.text_bl {
    font-size: 1vw;

    margin: 0 auto;
    text-align: center;
}
.inp .label {
    position: absolute;
    top: 20px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    pointer-events: none;
}
.inp .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
}
.inp input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 24px 12px 0 12px;
    height: 8.1vh;
    font-size: 1.2vw;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 1);
    color: #000;
    transition: all 0.15s ease;
}
.inp input:hover {
    background: rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.inp input:not(:-moz-placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:-ms-input-placeholder) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
    box-shadow: inset 0 -2px 0 #643BE0;
}
.inp input:focus + .label {
    color: #643BE0;
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus + .label + .focus-bg {
    transform: scaleX(1);
    transition: all 0.1s ease;
}

.download_modal .btn_close {
    position: absolute;
    right: 2.6vw;
    top: 4.6vh;
    width: 2.6vw;
    height: 2.6vw;
    cursor: pointer;
}
.download_modal .submit {
    cursor: pointer;
    font-size: 1.2vw;
    border: 1px solid #ccc;
    outline: none;
    width: 100%;
    max-width: 26vw;
    height: 8.1vh;
    transition: 0.5s ease-in-out;
}
.download_modal .submit:hover,.download_modal .submit:focus,.download_modal .submit:active {
    border: 1px solid #643BE0;
    color: #FFFFFF;
    background: #643BE0;
    transition: 0.5s ease-in-out;
}
.download_modal .line1 {
    height: 1px;
    width: 100%;
    background: #111111;
    transform: translate(0%,50%) rotate(45deg);
    margin-top: 14px;
}
.download_modal .line2 {
    height: 1px;
    width: 100%;
    background: #111111;
    transform: translate(0%,50%) rotate(-45deg);
}

.modal a{
    color: #111111;
}
.address_block {
    padding-top: 120px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}
.adress_wrap {
    display: flex;
}
.adress_wrap .divider {
    height: 1px;
    width: 100%;
    background: #111111;
    margin: 10px 0;
}
.address_block ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.address_block ul li{
    list-style: none;
    margin: 150px 20px;
}
.bot_cube {
    position: absolute;
    bottom: -2vh;
    width: 100%;
    max-width: 60vw;
    left: 50%;
    transform: translate(-50%, 0px);
    pointer-events: none;
}
.address_block ul li a{
    color: #111111;
}
.address_block .col-50{
    padding: 0 5vw 0 0;
}

@media screen and (max-width: 1024px) {
    body {
        font-size: 14px;
        line-height: 28px;
    }
    .mobile_menu {
        display: flex;
    }
    .faq .text {
        justify-content: start;
    }
    .btn_menu {
        position: absolute;
        top: 35px;
        right: 50px;
    }
    .portfolio .mobile_menu {
        color: #111111;
    }
    .portfolio .container {
        width: 100%;
    }
    .portfolio .image_port_cube {
        right: -50px;
        top: 200px;
    }
    .portfolio .title_in_bl {
        font-size: 20px;
        margin: 10px 0;
    }
    .image_port_cube {

    }
    .menu {
        display: none;
    }
    .menu_overlay {
        position: fixed;
        left: 100vw;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999999;
        opacity: 0;
        visibility: hidden;
    }
    .mobile_menu .moveA {
        display: block;
    }
    .mobile_menu ul{
        display: flex;
        flex-direction: column;
    }
    .mobile_menu li{
        list-style: none;
        margin: 20px 0;
    }
    .download_btn a{
        color: #FFFFFF;
    }
    .btn_close {
        position: absolute;
        right: 50px;
        top: 50px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .line1 {
        height: 1px;
        width: 100%;
        background: #111111;
        transform: translate(0%,50%) rotate(45deg);
        margin-top: 14px;
    }
    .line2 {
        height: 1px;
        width: 100%;
        background: #111111;
        transform: translate(0%,50%) rotate(-45deg);
    }
    .mission, .bespoke_service, .note {
        display: flex;
        padding: 120px 0;
    }
    .faq_section .white_block .faq {
        padding: 120px 0 0;
    }
    .faq_wrap .panel{
        font-size: 1vw;
    }
    .mt150 {
        margin-top: 20px;
    }
    .human_img_wrap .line {
        margin: -11px 0 0 0;
    }
    .human_img.woman {
        margin-top: 239px;
    }
    .faq .col-50:last-child {
        width: 100%;
    }
    .modal {
        transform: translate(-50%,35%);
    }
}
@media screen and (max-width: 1024px) and (orientation: portrait){
    .logo {
        font-size: 20px;
        max-width: none;
    }
    .address1, .address2 {
        display: none;
    }
    .slogan {
        font-size: 40px;
        line-height: 40px;
    }
    .slogan span:after {
        height: 2px;
        background: #FFFFFF;
        top: 38px;
        left: 0;
    }
    .nira_two_models .nira_one_model_text {
        max-width: none;
        bottom: -4%;
    }
    .header_bot {
        padding: 40px 5%;
    }
    .container {
        width: 100%;
    }
    .top_cube {
        width: 400px;
    }
    .content .sub_title {
        margin: 100px 5% 0;
    }
    h1 {
        max-width: 800px;
        text-align: center;
        margin: 45px auto 50px;
        font-size: 30px;
        line-height: 2.7778vw;
        display: block;
    }
    .col-50{
        width: 100%;
    }
    .about_section {
        flex-direction: column;
    }
    .sub_title {
        font-size: 12px;
        padding: 0px 15px;
    }
    .status_bar {
        right: 5%;
    }
    .bottom_text {
        left: 5%;
    }
    .mission, .bespoke_service, .note {
        flex-direction: column;
        padding: 0 0 120px;
        overflow: hidden;
    }
    .mission_section .cube_img {
        max-width: 440px;
        margin: -250px auto 0;
    }
    .mission_section .cover {
        display: none;
    }
    .bespoke_service .col-50:last-child{
        display: none;
    }
    .note .col-50:first-child{
        display: none;
    }
    .price_wrap {
        flex-direction: column;
    }
    .note {
        padding: 0;
    }
    .price_section .item.active {
        height: 250%;
    }
    .price_section .item {
        width: 100% !important;
    }
    .rad_big {
        width: 180px;
        height: 180px;
        left: 85%;
    }
    .rad_med {
        width: 56px;
        height: 56px;
        left: 60%;
        top: 30%;
    }
    .rad_sm {
        height: 16px;
        width: 16px;
        left: 36%;
        top: 50%;
    }
    .name_plan {
        font-size: 30px;
    }
    .price_section .item.active .name_plan {
        top: 30%;
    }
    .price_section .price {
        font-size: 30px;
    }
    .faq_section .white_block .faq {
        flex-direction: column;
        overflow: hidden;
    }
    .faq .col-50:last-child {
        padding: 0 5%;
    }
    .faq .cube_img {
        left: 50%;
        width: 400px;
        overflow: hidden;
        top: 0;
        transform: translate(-50%, -120%);
    }
    .faq_section .white_block .faq {
        padding: 275px 0 0;
    }
    .testimonials_section .text .sub_title {
        margin: 150px 5% 0px;
    }
    .testimonials_section .text {
        max-width: none;
        margin: 0;
        text-align: left;
    }
    .testimonials_section .text p {
        margin-left: 5%;
    }
    .testimonials_section .col-50 {
        padding: 0 5%;
    }
    .human_img.man {
        max-width: none;
    }
    .human_img.woman {
        max-width: none;
    }
    .human_img.woman {
        margin-top: 200%;
    }
    .absolute_img {
        position: absolute;
        right: -10vw;
        max-width: 43.78vw;
        top: 430px;
    }
    .client_section_wrap {
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        padding: 150px 5%;
    }
    .col-30 {
        width: 100%;
    }
    .col-70 {
        width: 100%;
        margin-top: 150px;
    }
    .client_section {
        height: auto;
    }
    .grid img {
        width: 19vw;
    }
    .grid {
        justify-content: space-between;
    }
    .address_block ul li {
        margin: 0;
    }
    .address_block ul {
        flex-direction: column;
        align-items: flex-end;
    }
    .address_block {
        padding: 120px 0 200px;
    }
    .bot_cube {
        bottom: -1vh;
        width: 130%;
        max-width: none;
    }
    .accordion,.faq_wrap .panel {
        font-size: 14px;
    }
    .testimonials .name p {
        font-size: 14px;
    }
    .testimonials .name:not(p) {
        font-size: 25px;
    }
    .client_section .text p {
        font-size: 30px;
        line-height: 45px;
        max-width: 70%;
    }
    .logo_name {
        font-size: 25px;
    }
    .social_block .text {
        max-width: 60%;
    }
    .portfolio .btn_menu {
        top: 65px;
    }
    .download_modal .wrapper {
        width: 70%;
    }
    .download_modal .btn_close {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .text_bl {
        font-size: 14px;
    }
    .inp {
        max-width: 80%;
    }
    .download_modal .submit {
        max-width: 80%;
        font-size: 14px;
        height: 60px;
    }
    .inp input {
        height: 60px;
        font-size: 14px;
    }
}

@media screen and (max-width: 567px) and (orientation: portrait){
    .btn_menu {
        top: 30px;
        right: 30px;
    }
    .slogan {
        font-size: 25px;
        line-height: 25px;
    }
    .btn_close {
        top: 30px;
        right: 30px;
    }
    .y_line {
        margin: 0 auto;
        width: 80%;
    }
    .top_cube {
        width: 70%;
    }
    h1 {
        margin: 1em 0 !important;
    }
    .header_num {
        font-size: 25px;
        top: 30px;
        left: 30px;
    }
    .header_num {
        width: 63px;
    }
    .title_in_bl {
        margin: 10px 0;
        font-size: 25px;
    }
    .sub_title {
        font-size: 12px;
    }
    .bottom_text {
        max-width: 85%;
    }
    .mission_section .cube_img {
        max-width: 66%;
        margin: -150px auto 50px;
    }
    .mission, .bespoke_service, .note {
        padding: 0;
    }
    .price_section .description {
        padding: 2% 5%;
    }
    .name_plan span {
        width: 16px;
        height: 16px;
    }
    .faq .cube_img {
        left: 50%;
        width: 80%;
        overflow: hidden;
        top: 0;
        transform: translate(-50%, -125%);
    }
    .white_block p {
        margin: 20px 0;
    }
    .testimonials_section .text p {
        font-size: 30px;
    }
    .testimonials.row{
        flex-direction: column;
    }
    .absolute_img {
        display: none;
    }
    .human_img.woman {
        margin-top: 20%;
    }
    .testimonials.row:last-child {
        margin-top: 20%;
    }
    .testimonials.row:last-child {
        padding: 0 0 100px 0;
    }
    .grid img {
        object-fit: cover;
    }
    .social_block {
        flex-direction: column;
    }
    .btn_social {
        margin-top: 30px;
    }
    .address_block {
        flex-direction: column;
        align-items: start;
    }
    .address_block nav{
        margin-top: 50px;
    }
    .address_block ul {
        align-items: flex-start;
    }
    .social_block .text {
        max-width: none;
    }
    .address_block .col-50 {
        padding: 0 10% 0 0;
    }
    .address_block .col-50:last-child {
        padding:0;
    }
    .line_port {
        margin-left: 5%;
        width: 90%;
    }
    .portfolio .btn_menu {
        top: 35px;
    }
    .text_block {
        font-size: 30px;
        line-height: 40px;
    }
    .image_port_cube {
        display: none;
    }
    .nira_one_model_text {
        display: none;
    }
    .nira_two_models {
        flex-direction: column;
    }
    .w50 {
        width: 100%;
        height: 100vh;
    }
    .nira_two_models {
        height: 100vh;
    }
    .download_modal .wrapper {
        width: 95%;
        height: 60%;
        padding: 5%;
    }
    .download_modal .submit {
        height: 55px;
        font-size: 14px;
    }
    .inp input {
        height: 55px;
        font-size: 14px;
    }
    .download_modal .btn_close {
        right: 30px;
        top: 30px;
    }
    .text_bl {
        text-align: center;
        max-width: 215px;
    }
    .faq_section .white_block .faq {
        padding: 100px 0 0;
    }
    .header_bot {
        padding: 0px 5%;
        margin-bottom: 100px;
    }
}




//****************   hide sections *******/
.testimonials_section {
    display: none;
}
.price_section {
    display: none;
}
.see_more {
    opacity: 0;
    visibility: hidden;
}
.faq .cube_img {
    display: none;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
    .bottom_text {
        top: 65%;
        bottom: inherit;
    }
    .webgl{
        top: 40px
    }
}